@font-face {
    font-family: Obviously;
    src: local('Obviously.otf'), url("./Obviously.otf") format('opentype');
}


@font-face {
    font-family: Obviously-Bold;
    src: local('Gobold-Regular.otf'), url("./Gobold-Regular.otf") format('opentype');
}

body {
    font-family:  'Obviously', sans-serif;
    font-weight: 400;
    background-color: #FFFFFF !important;
    color: #213875 !important;
}

.main {
    margin-top: 140px;
}

main {
    min-height: unset !important;
}

.heroimg {
    margin-top: -10px;
    margin-bottom: 0px;
    width: 70%;
    margin-right: 0;
    margin-left: auto;
    text-align: right;
    display: block;
}
h2 img {
    width: 110px;
    margin-left: 10px;
    margin-top: -10px;
}

.contract-text {
    margin-top: 0px;
    margin-bottom: 40px;
    font-weight: 600;
    color: #223675;
    font-size: 0.8rem;
    font-family: 'Obviously', Roboto, sans-serif;
    letter-spacing: 1px;
    width: 50%;
}

.right {
    text-align: right;
    margin-right: 0;
}

.tagline {
    text-align: center;
    font-family: Outfit-Regular, Roboto, sans-serif;
    font-size: 1.4rem;
    margin-left: 15px;
    opacity: 0.7;
}

#tagline-r, #tagline-i, #tagline-c, #tagline-h {
    display: none;
}

#placeholder {
    visibility: hidden;
    font-size: 1.4rem;
    margin-left: 15px;
}

#R:hover, #I:hover, #C:hover, #H:hover {    
    color: #ed1b24;
    cursor: default;
}

.center { 
    text-align: center;
    width: 100%;
}

.contract-link {
    text-decoration: none;
    color: #bfc500;
    font-weight: 400;
}

.contract-link:hover {
    opacity: 0.7;
    color:#bfc500 !important;
}

.main button {
    border-radius: 20px;
}
.connect-button.connect-button--disconnected:before {
    background: #f16a5d;
}

.connect-button.connect-button--connected:before {
    background: #8fd300;
}
.connect-button.connect-button--connected:before, .connect-button.connect-button--disconnected:before {
    content: "";
    position: absolute;
    left: 1rem;
    top: calc(50% - 0.3rem);
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
}

body .mint-button-a {
    background-color: #bfc500;
border: none;
color: #213875 !important;
padding: 0.7rem 2rem;
letter-spacing: 2px;
border-radius: 0;
font-family: Outfit-Regular, Roboto, sans-serif;
font-weight: 600;
font-size: 1.2rem;
margin-top: 60px;
text-decoration: none;
display: inline-block;
}


.feedback {
    margin-top: 20px;
    color: #ffffff;
    text-align: center;
    font-family: 'Obviously';
    font-weight: 600;
    width: 300px;
}

.count {
    display: block;
    font-size: 0.9rem;
    text-align: left;
    color: #ffffff;
    text-shadow: 0px 0px 10px rgb(33 56 117 / 25%); 
    margin-left: 40px;
}
.nav-link {
    padding-left: 0 !important;
}

.text-end .social-icons {
        text-align: center;
        display: inline-block;
        margin-right: 30px;
}


.my-masonry-grid {
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }
  
  .my-masonry-grid .img-thumbnail {
      border-radius: 0px;
      border: 3px solid white;
      padding: 0px;
  }
  
  .my-masonry-grid_column {
  
    background-clip: padding-box;
  }
  
  .my-masonry-grid_column > div {
  /*  margin-bottom: 30px;*/
  }
  
    .my-masonry-grid img {
          transition: 0.3s linear;
  }
  
  .my-masonry-grid img:hover {
      cursor: pointer;
      transition: 0.3s linear;
      transform: scale(1.01);
  }

p a {
    text-decoration: none;
    color: #000;
}

ol a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
}

.okay {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.banner.memes {
    background-color: #fff;
    color: #000;
    padding-top: 50px;
    padding-bottom: 0px;
    text-align: center;
    /* border-style: solid;
    border-width: 0px 0px 20px 0px;
    border-image: url(header-white.svg) 0 0 10 0 stretch stretch;
    border-image-outset: 6px !important; */
    position: relative;
    z-index: 100;
}

footer {
    z-index: -10;
    margin-top: 0px !important;
}

.data {
    font-family: 'Obviously-Bold', sans-serif;
    font-size: 1.4rem;
    display: block;
    margin-bottom: -10px;
    color: #000;
    text-decoration: none;
    transition: 0.3s linear;
}
.data:hover {
    transform: scale(1.02);
    transition: 0.3s linear;
    color: #000 !important;
}

.banner {
    background-color: #223675;
    color: #ffffff;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
}

.banner.mc {
    background-color: #da1332;
    color: #fff;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    margin-bottom: 0px !important;
    /* border-style: solid;
    border-width: 20px 0px 20px 0px;
    border-image: url(border-top.svg) 10 0 10 0 stretch stretch;
    border-image-outset: 6px !important; */
    position: relative;
    z-index: 30;
    font-size: 20px;
    font-family: 'Obviously-Bold', sans-serif;
}

.banner.mc p {
    color: #fff;
    margin-bottom: 40px;
    font-weight: bold;
}

h2 {
    font-family: 'Obviously-Bold', sans-serif;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 10px;
    color: #fff;
    font-size: 120px;
    text-align: left;
    line-height: 1 !important;
}

.small {
    font-size: 50px;
    color: #fff;
    display: block;
    margin-top: 100px;
    margin-bottom: -120px;
    padding-left: 4px;
}

h3 {
    font-family: 'Obviously-Bold', sans-serif;
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 36px;
}

.what-text {
    margin-bottom: 20px;
    padding-left: 30px;
}

.white {
    color: #ffffff;
    text-shadow: 0px 0px 10px rgb(33 56 117 / 25%);
}

.logo {
    width: 160px;
    display: block;
    margin-left: 0;
    margin-right: auto;
    margin-top: 0px;
    z-index: 10;
}

header {
    z-index: 20;
    background-color: #fff;
    padding-left: 40px;
    /* border-bottom: 4px solid #fff; */
    margin-bottom: 0px !important;
    border-bottom: 6px solid #da1332;
    position: relative;
}

.social-icons {
    z-index: 10;
}

.text-end {
    z-index: 100;
    padding-right: 100px;
}

.mc-icon {
    display: inline-block;
    width: 140px;
    margin-bottom: 20px;
}

/* .header-bg:before {
    display: block;
    content: '';
    width: 100%;
    height: 105px;
    background: #000;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    z-index: 1;
} */

.topnav {
    overflow: hidden;
    position: relative;
  }
  
  .topnav #myLinks {
    text-align: center;
    display: none;
  }

  #myLinks img {
    width: 45px;
  }

  #myLinks .col-2 {
    display: inline-block;
  }
  
  .topnav a {
    color: white;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    display: block;
  }

  .clear {
    clear: both;
    width: 100%;
    flex: content;
  }
  
  .topnav a.icon {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .topnav a:hover {
    color: black;
  }
  
  .active {
    color: white;
  }

.hero {
    padding-top: 10px;
    padding-bottom: 0px;
    background-color: #0B325E;
    color: #fff;
    width: 100%;
    height: 100%;
    margin-bottom: 0px !important;
    background-image: url("./background.png");
    background-size: cover;
    /* border-width: 0px 0px 20px 0px;
    border-image: url(header-white.svg) 0 0 10 0 stretch stretch;
    border-image-outset: 5px !important; */
    position: relative;
    z-index: 10;
}

.section-bg {
background-color: #0B325E;
    width: 100%;
    height: 100%;
    text-align: center;
    /* border-style: solid;
    border-width: 0px 0px 20px 0px;
    border-image: url(header-white.svg) 0 0 10 0 stretch stretch;
    border-image-outset: 6px !important; */
    position: relative;
}

.section-bg h3 {
    font-size: 36px;
    z-index: 1;
}

.memes h3 {
    padding-bottom: 30px;
}

.banner a {
    text-decoration: underline;
}

.banner a:hover {
    color: #fff !important;
}

.banner {
    /* border-style: solid;
    border-width: 0px 0px 20px 0px;
    border-image: url(border-blue.svg) 0 0 10 0 stretch stretch;
    border-image-outset: 6px !important; */
    position: relative;
    z-index: 12;
}

.banner p {
    color: #ffffff;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-size: 24px;
    font-family: 'Obviously', Roboto, sans-serif;
    font-weight: 500;
}

.banner ol {
    font-size: 20px;
    margin-top: 30px;
}

.banner h3 {
    margin-bottom: 20px;
}

.section-bg p {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Obviously', sans-serif;
    text-align: justify;
    z-index: 1;
    color: #000;
}

.section-bg .strong {
    text-align: center;
    font-weight: bold;
    z-index: 1;
    color: #223675;
}

.section-bg h3 {
    margin-bottom: 20px;
    z-index: 1;
    color: #000;
}

.banner li {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    margin-top: 5px;
    font-family: 'Obviously', Roboto, sans-serif;
    font-weight: 500;
}

.section-bg .overlay {
    padding-top: 80px;
    z-index: -1;
    padding-bottom: 40px;
}


.header-bg {
    margin-top: 0px;
    padding-bottom: 40px;
    z-index: 1000;
}

.what img {
    width: 50px;
}

.main p {
    color: #000;
    margin-top: 20px;
    margin-bottom: 40px;
}

.main .demo img {
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: 0;
    clear: both;
    border-radius: 20px;
}

.main .demo video {
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: 0;
    clear: both;
    border-radius: 20px;
    margin-top: 20px;
}

.main .dog img {
    width: 80%;
    display: block;
    margin-right: auto;
    margin-left: 0;
    clear: both;
    border-radius: 20px;
}

body p.highlight {
    font-family: 'Poppins', Roboto, sans-serif !important;
    margin-top: 50px;
}

body .roadmap .blue {
    font-size: 1.5rem;
    font-family: 'Poppins', Roboto, sans-serif !important;
}

body .roadmap p {
    margin-bottom: 40px;
}

body p {
    font-size: 1.3rem;
    font-weight: 400;
    color: #213875;
    -webkit-font-smoothing: antialiased;
}

body .utility p {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 8px;
    -webkit-font-smoothing: antialiased;
    color: #c9c9c9;
}

body .margin-top p {
    text-align: justify !important;
}

body .lead {
    text-align: center !important;
    font-family: 'Poppins', Roboto, sans-serif;
}

.sb {
    float: left;
    margin-bottom: .5rem;
    position: relative;
    width: 100%;
}

.memes .container {
    margin-bottom: 40px;
}

.section-break-4 {
    border: 0;
    background-color: transparent;
    height: 0;
    margin: 3rem auto 5rem;
    text-align: center;
    width: 100%;
}
.section-break-4:before, .section-break-4:after {
    background-color: #213875;
    content: '';
    height: 1px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 9%;
}
.section-break-4:before {
    transform: rotate(10deg);
}
.section-break-4:after {
    transform: rotate(-10deg);
}


body .intro {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
    margin-top: 0px;
    margin-bottom: 40px;
}
.heading { 
    font-family: 'Poppins', Roboto, sans-serif;
    color:#213875;
    letter-spacing: 1px;
}

header {
    margin-bottom: 0.3rem;
}

.yellow {
    color: #FFD700;
}

.faint {
    opacity: 0.7;
}

body  .expanded {
    line-height: 30px;
}

body .container .btn-primary {
    background-color: rgba(195, 205, 230, 0.243);
    border: none;
    font-family: Outfit-Regular, Roboto, sans-serif;
    position: relative;
    /* -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black; */
}

body .container .btn-primary:hover {
    background-color: rgba(195, 205, 230, 0.343)    ;
    border: none;
    /* -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black; */
}

body .container .btn {
    padding: 1rem 1.4rem 1rem 2.3rem;
    font-size: 0.9rem;
    border-radius: 20px !important;
}

body .container .btn-primary:focus {
    box-shadow: none;
    -webkit-box-shadow: none; 
}

h1.heading {
    margin-bottom: 1.2rem;
}

.big-header {
    font-size: 3rem;
}

body a:hover {
    color: #000000 !important;
}

.marg-top {
    margin-top: 60px;
}

.section-break-5 {
    background-color: transparent;
    border: 0;
    border-top: 1px solid #FFF;
    display: block;
    height: 0;
    margin: 3rem auto 3rem;
    text-align: center;
    width: 50%;
}
.section-break-5:before {
    background-color: #000;
    color: #FFF;
    content: 'j';
    display: inline-block;
    font-size: 1.6rem;
    left: 50%;
    letter-spacing: 0;
    line-height: 1.6rem;
    text-indent: 0;
    text-rendering: geometricPrecision;
    transform: translateY(-65%);
    width: 15px;
}

body .utility .button-container {
    text-align: center;
    margin-left: auto !important;
    margin-right: auto !important;
    display: block;
    clear: both;
    margin-top: 50px;
}
body .margin-top {
    margin-top: 3rem;
}

body h1 {
    font-family: 'Obviously-Bold', Roboto, sans-serif;
    margin-top: 0px;
    font-size: 3.6rem;
    margin-bottom: 50px;
    font-weight: 800;
}

body .heading.graffiti.blue {
    margin-bottom: 5px !important;
    margin-top: 30px;
}

body h2.heading.graffiti.blue {
    font-size: 1.6rem;
}

body h2.heading.graffiti.blue.details {
    font-size: 1.3rem;
    margin-bottom: 4px !important;
    margin-top: 25px;
}

body .utility .heading.graffiti.blue {
    margin-bottom: 10px !important;
    margin-top: 50px;
    letter-spacing: 2px;
}

body .margin-bottom {
    margin-bottom: 40px;
}
.divider
{
	position: relative;
	margin-top: 60px;
	height: 1px;
}

.div-transparent:before
{
	content: "";
	position: absolute;
	top: 0;
	left: 5%;
	right: 5%;
	width: 90%;
	height: 1px;
	background-image: linear-gradient(to right, transparent, rgb(48,49,51), transparent);
}

body .utility {
    margin-bottom:80px;
    margin-top: 80px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

body .utility a {
    text-decoration: none;
    color: #b9334c;
}

body .utility a:hover {
    text-decoration: none;
    color: #fae9a5 !important;
}

body .small-margin-top p {
    text-align: justify !important;
}

.banner h3 {
    margin-bottom: 40px;
}

.banner strong {
    margin-top: 30px;
    margin-bottom: 30px;
    display: block;
}

.buyimg {
    width: 100%;
    margin-top: 10px;
}

.hero a {
    transition: 0.3s linear all;
}

.hero a:hover {
    transition: 0.3s linear all;
    font-family: 'Obviously-Bold', sans-serif;
}

body .small-margin-top {
    margin-top: 2rem;
}

body .col-image {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    display: block;
}

body .bold-text {
    font-weight: bold;
}



body .blue {
    color: #b20000 !important;
}

header a {
    font-size: 1.8rem;
}

footer {
    border-top: 6px solid #da1332;
    margin-top: 1rem;
    margin-bottom: 0px;
    background-color: #fff;
    color: #223675;
}

.social-icons {
    text-align: center;
}

.social-icons img {
    width: 45px;
}

.social-icons img:hover {
    opacity: 0.8;
    -webkit-transition: 300ms ease all;
    -o-transition: 300ms ease all;
    transition: 300ms ease all;
}

/* hr {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    display: block;
} */

body .text-center {
    text-align: center;
}
#mint-button {
    margin-top: 20px !important;
    border-radius: 20px;
    text-align: center;
    margin-left: 70px;
    margin-right: auto;
    display: block;
    margin-bottom: 30px;
}
body .mintAmount {
    font-family: 'Gobold-Regular', sans-serif;
    font-size: 2.8rem;
    margin-bottom: -10px;
    display: block;
    width: 100px;
    letter-spacing: 2px;
    color: #ffffff;
    text-shadow: 0px 0px 10px rgb(33 56 117 / 25%);
}

body .mintPrice {
    font-size: 1.3rem;
    font-family: 'Poppins', Roboto, sans-serif;
    margin-top: -15px;
    opacity: 0.6;
    letter-spacing: 1px;
}

body .mint-area {
    margin-top: 30px;
}


.terms a {
    text-decoration: none;
    font-family: 'Poppins', Roboto, sans-serif;
    color: #000000;
}

.terms.contract a {
    text-decoration: none;
    font-family: 'Obviously', sans-serif !important;
    color: #000000;
}

.contract {
    margin-bottom: 80px;
}


.terms a:hover {
    text-decoration: none;
    color: rgb(218, 218, 218);
}

.mobile {
    visibility: hidden;
}


@media only screen and (max-width: 800px) {

    .justify-content-center {
        justify-content: unset !important;
    }

    header {
        margin-top: -40px;
    }

    .logo {
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-top: 50px;
        margin-bottom: 5px;
        text-align: center;
        width: 180px;
    }


    .justify-content-center {
        justify-content: center !important;
    }
    header {
        justify-content: center !important;
    }

    .hamburger img {
        padding-top: 60px;
    }

    .nav {
        display: block !important;
        width: 100%;
    }

    .buyimg {
        width: 90%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    header {
        padding-left: 0px;
    }

    .LP {
        margin-bottom: 40px;
    }

    .hero {
        padding-top: 10px;
        padding-bottom: 0px;
        background-color: #0B325E;
        width: 100%;
        height: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

    .mint-controls {
        margin-left: auto !important;
        text-align: center;
        justify-content: center !important;
        margin-right: auto;
        -webkit-box-pack: center !important;
    }

    .social-icons {
        margin-top: 30px !important;
    }

    .connect-button, #mint-button {
        margin-left: auto !important;
    }

    .count {
        margin-left: auto !important;
        text-align: center !important;
    }

    .banner p {
        width: 90%;
    }

    .section-bg p {
        width: 90%;
    }

    .hamburger {
        transition: 0.3s linear all;
    }

    .hamburger img {
        width: 45px;
    }

    .hamburger:hover {
        color: white !important;
        transform: scale(1.1);
        transition: 0.3s linear all;
    }

    .section-bg li {
        width: 80%;
    }

    .mobile {
        visibility: visible !important;
    }
    footer 
    .mobile {
        visibility: visible !important;
    }

    .contract-text, .right {
        display: block;
        width: 100%;
        text-align: center;
    }

    .contract-text {
        margin-top: 50px;
    }

    .contract-text.right {
        margin-top: 5px !important;
    }

    h2 {
        font-family: 'Obviously-Bold', sans-serif;
        font-weight: 700;
        letter-spacing: 1px;
        margin-top: -30px !important;
        margin-bottom: 40px;
        color: #fff;
        font-size: 5.8rem !important;
        text-align: center !important;
        line-height: 1 !important;
    }

    .small {
        margin-top: 30px;
        margin-bottom: -80px;
    }

    .hero p {
        font-size: 22px;
    }

    .heroimg {
        margin-top: 40px;
        margin-left: auto;
        margin-right: auto;
        width: 80%;
    }

    .what-text {
        margin-bottom: 20px;
        padding-left: 0px !important;
        margin-top: 20px;
    }
    .main .demo img {
        width: 60%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        clear: both;
    }

    .main .dog img {
        width: 60%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        clear: both;
    }

    .main {
        text-align: center;
        margin-top: 90px;
    }

    body .utility {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

        .team-card {
        background-color: #313131;
        border-radius: 10px;
        margin-left: 10%;
        margin-right: 10%;
        width: 80%;
        margin-top: 30px;
    }
    .team-card-left {
        background-color: #313131;
        border-radius: 10px;
        margin-left: 10%;
        margin-right: 10%;
        width: 80%;
    }
    body .col-image {
        width: 60%;
    }

    body .container .desktop-only {
        display: none;
    }

    body .container .mobile-only {
        display: block !important;
    }

    body .container .mobile-only img {
        width: 100px;
    }

    .divider2 {
        margin-bottom: -20px !important;
    }

    body .mint-header-border {
        top: 20px !important;
        position: relative;
        margin-top: -40px;
    }

    body .mint-footer-divider-image {
        position: relative;
        top: -30px !important;
        margin-bottom: -20px !important;
    }

    body .intro {
        width: 100%;
    }
}

@media only screen and (max-width: 1170px) {

    .mobile {
        visibility: hidden;
    }

    h2 {
        font-family: 'Obviously-Bold', sans-serif;
        font-weight: 700;
        letter-spacing: 1px;
        margin-top: 30px;
        margin-bottom: 40px;
        color: #fff;
        font-size: 46px;
        text-align: left;
        line-height: 1 !important;
    }

    .text-end {
        display: none;
    }

    .what-text {
        margin-bottom: 20px;
        padding-left: 30px;
    }

    body .utility {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    body .intro {
        width: 90%;
    }

    .desktop-visible-only {
        display: none;
    }

    .mint-footer-divider-image {
        position: relative;
        top: -40px !important;
        margin-bottom: -40px !important;
    }

    .mint-header-border {
        top: 30px !important;
        position: relative;
        margin-top: -40px;
    }

    /* .team-card {
        background-color: #313131;
        border-radius: 10px;
        margin-left: 10%;
        margin-right: 10%;
        width: 80%;
        margin-top: 30px;
    }
    .team-card-left {
        background-color: #313131;
        border-radius: 10px;
        margin-left: 10%;
        margin-right: 10%;
        width: 80%;
    } */

    body .container .vertical-timeline .vertical-timeline-element-icon {
        position: absolute;
        top: 0;
        left: 10px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        -webkit-box-shadow: none;
        box-shadow: none;
        margin-left: 0;
    }

    body .container .vertical-timeline .text-right {
        text-align: left !important;
    }

    body .container .vertical-timeline .vertical-timeline-element-content p {
        font-size: 1rem;
        line-height: 1.4rem;
    }

    .divider2 {
        margin-bottom: -40px;
    }

    h2 img {
        width: 60px;
        margin-left: 10px;
        margin-top: -10px;
    }
}


body .mint-countdown span {
    font-family: 'Roboto Mono', monospace !important;
    font-size: 40px;
    letter-spacing: 3px;
    background-color: #bb2f27;
    width: 340px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 4px;
}


.mint-header-border {
    top: 40px;
    position: relative;
    margin-top: -40px;
}

.mint-footer-divider-image {
    position: relative;
    top: -80px;
    margin-bottom: -40px;
}

body .mint-page p {
    margin-bottom: 0.1rem;
}

body .countdown-counter {
    margin-bottom: 20px;
}

body .margin-top li {
    padding-top: 6px;
    padding-bottom: 6px;

}

.mint-container {
    text-align: center;
    margin-top: 60px;
}

.mint-container .rabbits {
    margin-bottom: 20px;
    margin-top: 20px;
}

.mint-container h2 {
    letter-spacing: 2px;
    font-size: 1.8rem;
}

.mint-container .connect {
    margin-top: 50px;
}

.mint-count {
    letter-spacing: 1px;
    opacity: 0.8;
    color: #213875;
    font-family: 'Poppins', Roboto, sans-serif;
}

.spaced {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
}

.mint-container .btn  {
    background-color: #FFD700;
border-color: #FFD700;
color: #213875;
padding: 0.7rem 1rem;
letter-spacing: 2px;
border-radius: 1rem;
font-family: 'Poppins', Roboto, sans-serif;
border-style: solid;
font-size: 1.2rem;
border: 1px;
margin-top: 50px;
}

.mint-container .btn:active {
    background-color: #FFD700;
    border-color: #FFD700;
    color: #213875;
    -webkit-box-shadow: 2px 5px 16px 0px #0B325E, 0px 0px 15px 5px rgba(255,215,0,0.8); 
    box-shadow: 2px 5px 16px 0px #0B325E, 0px 0px 15px 5px rgba(255,215,0,0.8);
  }
  .mint-container .btn:hover {
    background-color: #FFD700;
    border-color: #FFD700;
    color: #213875;
    -webkit-box-shadow: 2px 5px 16px 0px #0B325E, 0px 0px 15px 5px rgba(255,215,0,0.8); 
    box-shadow: 2px 5px 16px 0px #0B325E, 0px 0px 15px 5px rgba(255,215,0,0.8);
  }
  .mint-container .btn:disabled {
    background-color: #ddbd09;
    opacity: 0.4;
  }

  .mint-controls {
      margin-top: 20px;
      margin-bottom: 15px;
      margin-left: 20px;
  }










  .image-gallery-icon {
    color: #fff;
    transition: all 0.3s ease-out;
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    position: absolute;
    z-index: 4;
    filter: drop-shadow(0 2px 2px #1a1a1a);
}
@media (hover: hover) and (pointer: fine) {
    .image-gallery-icon:hover {
        color: #337ab7;
   }
    .image-gallery-icon:hover .image-gallery-svg {
        transform: scale(1.1);
   }
}
.image-gallery-icon:focus {
    outline: 2px solid #337ab7;
}
.image-gallery-using-mouse .image-gallery-icon:focus {
    outline: none;
}
.image-gallery-fullscreen-button, .image-gallery-play-button {
    bottom: 0;
    padding: 20px;
}
.image-gallery-fullscreen-button .image-gallery-svg, .image-gallery-play-button .image-gallery-svg {
    height: 28px;
    width: 28px;
}
@media (max-width: 768px) {
    .image-gallery-fullscreen-button, .image-gallery-play-button {
        padding: 15px;
   }
    .image-gallery-fullscreen-button .image-gallery-svg, .image-gallery-play-button .image-gallery-svg {
        height: 24px;
        width: 24px;
   }
}
@media (max-width: 480px) {
    .image-gallery-fullscreen-button, .image-gallery-play-button {
        padding: 10px;
   }
    .image-gallery-fullscreen-button .image-gallery-svg, .image-gallery-play-button .image-gallery-svg {
        height: 16px;
        width: 16px;
   }
}
.image-gallery-fullscreen-button {
    right: 0;
}
.image-gallery-play-button {
    left: 0;
}
.image-gallery-top-nav, .image-gallery-bottom-nav {
    padding: 10px 10px;
    left: 50%;
    transform: translateX(-50%);
}
.image-gallery-top-nav .image-gallery-svg, .image-gallery-bottom-nav .image-gallery-svg {
    height: 120px;
    width: 90px;
}
@media (max-width: 768px) {
    .image-gallery-top-nav .image-gallery-svg, .image-gallery-bottom-nav .image-gallery-svg {
        height: 72px;
        width: 48px;
   }
}
@media (max-width: 480px) {
    .image-gallery-top-nav .image-gallery-svg, .image-gallery-bottom-nav .image-gallery-svg {
        height: 48px;
        width: 36px;
   }
}
.image-gallery-top-nav[disabled], .image-gallery-bottom-nav[disabled] {
    cursor: disabled;
    opacity: 0.6;
    pointer-events: none;
}
.image-gallery-top-nav {
    top: 0;
}
.image-gallery-bottom-nav {
    bottom: 0;
}
.image-gallery-left-nav, .image-gallery-right-nav {
    padding: 50px 10px;
    top: 50%;
    transform: translateY(-50%);
}
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 120px;
    width: 60px;
}
@media (max-width: 768px) {
    .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
        height: 72px;
        width: 36px;
   }
}
@media (max-width: 480px) {
    .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
        height: 48px;
        width: 24px;
   }
}
.image-gallery-left-nav[disabled], .image-gallery-right-nav[disabled] {
    cursor: disabled;
    opacity: 0.6;
    pointer-events: none;
}
.image-gallery-left-nav {
    left: 0;
}
.image-gallery-right-nav {
    right: 0;
}
.image-gallery {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    position: relative;
}
.image-gallery.fullscreen-modal {
    background: #000;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 5;
}
.image-gallery.fullscreen-modal .image-gallery-content {
    top: 50%;
    transform: translateY(-50%);
}
.image-gallery-content {
    position: relative;
    line-height: 0;
    top: 0;
}
.image-gallery-content.fullscreen {
    background: #000;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 80px);
}
.image-gallery-content.image-gallery-thumbnails-left .image-gallery-slide .image-gallery-image, .image-gallery-content.image-gallery-thumbnails-right .image-gallery-slide .image-gallery-image {
    max-height: 100vh;
}
.image-gallery-slide-wrapper {
    position: relative;
}
.image-gallery-slide-wrapper.image-gallery-thumbnails-left, .image-gallery-slide-wrapper.image-gallery-thumbnails-right {
    display: inline-block;
    width: calc(100% - 110px);
}
@media (max-width: 768px) {
    .image-gallery-slide-wrapper.image-gallery-thumbnails-left, .image-gallery-slide-wrapper.image-gallery-thumbnails-right {
        width: calc(100% - 87px);
   }
}
.image-gallery-slide-wrapper.image-gallery-rtl {
    direction: rtl;
}
.image-gallery-slides {
    line-height: 0;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    text-align: center;
    touch-action: none;
}
.image-gallery-slide {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.image-gallery-slide.image-gallery-center {
    position: relative;
}
.image-gallery-slide .image-gallery-image {
    width: 100%;
    object-fit: contain;
}
.image-gallery-slide .image-gallery-description {
    background: rgba(0, 0, 0, 0.4);
    bottom: 70px;
    color: #fff;
    left: 0;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    white-space: normal;
}
@media (max-width: 768px) {
    .image-gallery-slide .image-gallery-description {
        bottom: 45px;
        font-size: 0.8em;
        padding: 8px 15px;
   }
}
.image-gallery-bullets {
    bottom: 20px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 80%;
    z-index: 4;
}
.image-gallery-bullets .image-gallery-bullets-container {
    margin: 0;
    padding: 0;
    text-align: center;
}
.image-gallery-bullets .image-gallery-bullet {
    appearance: none;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 50%;
    box-shadow: 0 2px 2px #1a1a1a;
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
    outline: none;
    padding: 5px;
    transition: all 0.2s ease-out;
}
@media (max-width: 768px) {
    .image-gallery-bullets .image-gallery-bullet {
        margin: 0 3px;
        padding: 3px;
   }
}
@media (max-width: 480px) {
    .image-gallery-bullets .image-gallery-bullet {
        padding: 2.7px;
   }
}
.image-gallery-bullets .image-gallery-bullet:focus {
    transform: scale(1.2);
    background: #337ab7;
    border: 1px solid #337ab7;
}
.image-gallery-bullets .image-gallery-bullet.active {
    transform: scale(1.2);
    border: 1px solid #fff;
    background: #fff;
}
@media (hover: hover) and (pointer: fine) {
    .image-gallery-bullets .image-gallery-bullet:hover {
        background: #337ab7;
        border: 1px solid #337ab7;
   }
    .image-gallery-bullets .image-gallery-bullet.active:hover {
        background: #337ab7;
   }
}
.image-gallery-bullets.image-gallery-bullets-vertical {
    left: 20px;
    right: auto;
    bottom: auto;
    width: auto;
    top: 50%;
    transform: translateY(-50%);
}
.image-gallery-bullets.image-gallery-bullets-vertical .image-gallery-bullet {
    display: block;
    margin: 12px 0;
}
@media (max-width: 768px) {
    .image-gallery-bullets.image-gallery-bullets-vertical .image-gallery-bullet {
        margin: 6px 0px;
        padding: 3px;
   }
}
@media (max-width: 480px) {
    .image-gallery-bullets.image-gallery-bullets-vertical .image-gallery-bullet {
        padding: 2.7px;
   }
}
.image-gallery-thumbnails-wrapper {
    position: relative;
}
.image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
    touch-action: pan-y;
}
.image-gallery-thumbnails-wrapper.thumbnails-swipe-vertical {
    touch-action: pan-x;
}
.image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
    direction: rtl;
}
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left, .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right {
    display: inline-block;
    vertical-align: top;
    width: 100px;
}
@media (max-width: 768px) {
    .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left, .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right {
        width: 81px;
   }
}
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left .image-gallery-thumbnails, .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right .image-gallery-thumbnails {
    height: 100%;
    width: 100%;
    left: 0;
    padding: 0;
    position: absolute;
    top: 0;
}
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left .image-gallery-thumbnails .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right .image-gallery-thumbnails .image-gallery-thumbnail {
    display: block;
    margin-right: 0;
    padding: 0;
}
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail {
    margin-left: 0;
    margin-top: 2px;
}
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left, .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right {
    margin: 0 5px;
}
@media (max-width: 768px) {
    .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left, .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right {
        margin: 0 3px;
   }
}
.image-gallery-thumbnails {
    overflow: hidden;
    padding: 5px 0;
}
@media (max-width: 768px) {
    .image-gallery-thumbnails {
        padding: 3px 0;
   }
}
.image-gallery-thumbnails .image-gallery-thumbnails-container {
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
}
.image-gallery-thumbnail {
    display: inline-block;
    border: 4px solid transparent;
    transition: border 0.3s ease-out;
    width: 100px;
    background: transparent;
    padding: 0;
}
@media (max-width: 768px) {
    .image-gallery-thumbnail {
        border: 3px solid transparent;
        width: 81px;
   }
}
.image-gallery-thumbnail + .image-gallery-thumbnail {
    margin-left: 2px;
}
.image-gallery-thumbnail .image-gallery-thumbnail-inner {
    display: block;
    position: relative;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    line-height: 0;
}
.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
    outline: none;
    border: 4px solid #337ab7;
}
@media (max-width: 768px) {
    .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
        border: 3px solid #337ab7;
   }
}
@media (hover: hover) and (pointer: fine) {
    .image-gallery-thumbnail:hover {
        outline: none;
        border: 4px solid #337ab7;
   }
}
@media (hover: hover) and (pointer: fine) and (max-width: 768px) {
    .image-gallery-thumbnail:hover {
        border: 3px solid #337ab7;
   }
}
.image-gallery-thumbnail-label {
    box-sizing: border-box;
    color: white;
    font-size: 1em;
    left: 0;
    line-height: 1em;
    padding: 5%;
    position: absolute;
    top: 50%;
    text-shadow: 0 2px 2px #1a1a1a;
    transform: translateY(-50%);
    white-space: normal;
    width: 100%;
}
@media (max-width: 768px) {
    .image-gallery-thumbnail-label {
        font-size: 0.8em;
        line-height: 0.8em;
   }
}
.image-gallery-index {
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 4;
}
@media (max-width: 768px) {
    .image-gallery-index {
        font-size: 0.8em;
        padding: 5px 10px;
   }
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
}

.image-item img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}
